












































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import VuetifyGoogleAutocomplete from "./VuetifyGoogleAutocomplete.vue";
import {
  dispatchCheckLoggedIn,
  dispatchSelectPlan,
  dispatchGetServiceAddress,
  dispatchGetBillingAddress,
  dispatchGetServices,
} from "@/store/main/actions";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import { readServiceAddress, readBillingAddress } from "@/store/main/getters";
import _ from "lodash";
import Progress from "./Progress.vue";
import { formatAddress } from "../utils";
import Loading from "./Loading.vue";

@Component({
  components: { VuetifyGoogleAutocomplete, Header, Footer, Progress, Loading },
})
export default class Plans extends Vue {
  public valid = true;
  public showLoading = false;
}
